import Navbar from "./components/Navbar";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="coming">
        <h2>Coming soon...</h2>
        <div className="contact">
          <p>In the meantime, reach me via email</p>
          <a href="mailto:annav.creator@gmail.com">annav.creator@gmail.com</a>
        </div>
      </div>
    </div>
  );
}

export default App;

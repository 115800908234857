import React from 'react';
import classes from "./Navbar.module.scss";
import logoWhiteSmall from "../assets/images/navbar/logo_white_small.png";
import navProfileWhite from "../assets/images/navbar/nav_profile_white.png";

function Navbar() {
    return (
        <header className={classes.header}>
            <nav className={classes.nav}>
                <div className={classes.logo}>
                    <a href='/'>
                        <img src={logoWhiteSmall} alt="Anna" />
                        <h1>ANNA VARLAMOVA</h1>
                    </a>
                </div>
                <div className={classes.profile}>
                    <a href='/' className={classes.profileLink} >
                        <img src={navProfileWhite} alt='profile' />
                    </a>
                </div>
            </nav>
        </header>
    );
}

export default Navbar;